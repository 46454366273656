import {
  PaymentControllerService,
  PaymentMasterInfoVo,
} from '@/__generated__/CommonApi';
import Banner3 from '@/assets/img/img_life_saving_gift_banner_3@2x.png';
import BannerM3 from '@/assets/img/img_life_saving_gift_banner_3_m@2x.png';
import Banner1 from '@/assets/img/img_life_saving_gift_banner_4@2x.jpg';
import BannerM1 from '@/assets/img/img_life_saving_gift_banner_4_m@2x.jpg';
import Banner2 from '@/assets/img/img_life_saving_gift_banner_5@2x.jpg';
import BannerM2 from '@/assets/img/img_life_saving_gift_banner_5_m@2x.jpg';
import Button from '@/components/Button';
import { CartItem } from '@/components/CartList';
import Container from '@/components/Container';
import { FormItem } from '@/components/Form/FormGroup';
import GiftList from '@/components/GiftList';
import Image from '@/components/Image';
import SnsButton from '@/components/SnsButton';
import { BigTit, H2, Tit } from '@/components/Titles';
import { StepBody, ToggleBox } from '@/components/ToggleContainer';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper, { PurchaseData } from '@/helpers/GtmHelper';
import {
  useFindSupportCategory,
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import SessionStorage from '@/services/SessionStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { Link, navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding-top: 96px;

  ${breakpoint(`tablet`)} {
    padding-top: 70px;
  }

  ${ToggleBox} {
    ${H2} {
      color: #2d2926;
    }
  }

  &.sec1 {
    ${BigTit} {
      color: #1cabe2;
      margin-bottom: 96px;
      font-weight: 400;
      ${breakpoint(`mobile`)} {
        font-size: 24px;
        margin-bottom: 40px;
      }
    }

    ${StepBody} {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 1920px;
        height: 100%;
        background: #f8f9fa;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }
    }

    p {
      margin-top: 30px;
    }
  }

  &.sec2 {
    padding: 240px 0;

    ${breakpoint(`tablet`)} {
      padding: 120px 0;
    }
  }
`;

const SecHeader = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  flex-wrap: wrap;

  &:before {
    content: '';
    display: block;
    width: 1520px;
    height: 100%;
    background: #374ea2;
    position: absolute;
    right: 0;
    top: 0;
  }

  ${breakpoint(`tablet`)} {
    margin-left: -20px;
    margin-right: -20px;
  }

  .col-left {
    width: 48.66%;
    position: relative;

    ${breakpoint(`tablet`)} {
      width: 100%;
    }
  }

  .col-right {
    width: 51.34%;
    position: relative;
    padding: 40px 28px;

    ${breakpoint(`tablet`)} {
      padding: 30px 20px;

      width: 100%;
    }

    ${H2} {
      letter-spacing: -1.6px;
      margin-bottom: 33px;

      ${breakpoint(`tablet`)} {
        font-size: 16px;
        margin-bottom: 24px;
      }
    }
  }
`;

const Bannerwrap = styled.div`
  padding-top: 252px;

  ${breakpoint(`tablet`)} {
    padding-top: 120px;
  }
`;

const BannerImage = styled.figure`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
`;

const ButtonFlex = styled.ul`
  display: flex;
  max-width: 760px;
  margin: 0 auto;
  margin-top: 96px;

  & > li {
    width: 50%;
    padding: 0 16px;

    button {
      width: 100%;
    }
  }
`;

const PayCompleteImage = styled.div`
  ${Tit} {
    display: block;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #1cabe2;
  }
  img {
    max-width: 100%;
  }
`;
const PayDetail = styled.ul`
  padding-bottom: 24px;
  li {
    padding-left: 96px;
    position: relative;
    line-height: 28px;
    & + li {
      margin-top: 8px;
    }
  }
  span {
    color: #2d2926;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
  }
  td {
  }
`;

const CompletePage: FC<PageProps> = observer(({ location }) => {
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const orderNumber = params.get(`orderNumber`);
  const popupStore = usePopupStore();
  const userStore = useUserStore();

  const [order, setOrder] = useState<PaymentMasterInfoVo>();
  const [orderData, setOrderData] = useState<PurchaseData>();
  const [gifts, setGifts] = useState<CartItem[]>([]);
  const supportAmount = order ? Number(order.totalAmount) : 0;
  const selectedCategory = useFindSupportCategory(
    order?.donationCode,
    order?.solicitCode,
  );

  useEffect(() => {
    // orderNumber가 없으면 튕기기
    if (!orderNumber) {
      popupStore.show(`후원 번호가 올바르지 않습니다`, {
        onConfirm: () => window.history.back(),
      });
    }
  }, [orderNumber, popupStore]);

  const loadOrder = useCallback(async () => {
    // 주문정보 불러오기
    const sessionOrderData = JSON.parse(SessionStorage.getItem('orderData'));
    // 주문토큰 불러오기
    const orderToken = SessionStorage.getItem(`orderToken`);
    if (!orderToken || !sessionOrderData) {
      popupStore.show(`후원 정보가 올바르지 않습니다`, {
        onConfirm: () => window.history.back(),
      });
    }
    if (orderNumber) {
      try {
        const {
          resultCode,
          resultMessage,
          data,
        } = await PaymentControllerService.getPaymentInfoUsingGet({
          orderNumber,
          orderToken,
        });
        // 성공이면
        if (resultCode === `success`) {
          // 세션 스토리지 주문정보 세팅
          setOrderData(sessionOrderData);
          // 데이터 세팅
          setOrder(data);

          // 상품목록 로딩
          const {
            data: igList,
          } = await PaymentControllerService.getIgInfoUsingGet({
            orderNumber,
            orderToken,
          });
          if (!igList || !igList.length) {
            popupStore.show(`선물 정보가 올바르지 않습니다`, {
              onConfirm: () => window.history.back(),
            });
          }

          setGifts(
            igList.map((gift: any) => ({
              productCode: gift.productCode,
              productName: gift.productName,
              productNameEn: gift.productNameEn,
              productPrice: Number(gift.productPrice),
              count: Number(gift.productCount),
              imgProductAttImgNo1_50: gift.imgProductAttImgNo1_50,
              imgProductAttImgNo1_140: gift.imgProductAttImgNo1_140,
              imgProductAttImgNo1_290: gift.imgProductAttImgNo1_290,
              imgProductAttImgNo1_420: gift.imgProductAttImgNo1_420,
            })),
          );
          return;
        }
        popupStore.show(resultMessage);
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            popupStore.show(e.response.data.resultMessage);
          } else if (e.response.status === 500) {
            popupStore.show(`서버 오류가 발생했습니다.`);
          }
        }
      }
    }
  }, [orderNumber, popupStore]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  // 로그인된 상태라면 회원정보 업데이트 (토큰 업데이트)
  useEffect(() => {
    if (userStore.isLoggedIn()) {
      userStore.refreshJwt();
    }
  }, [userStore]);

  // 주문정보 로드되면 GTM 이벤트 전송
  useEffect(() => {
    if (order && orderData) {
      // gtm purchase
      GtmHelper.purchase({
        campaignName: orderData?.campaignName,
        campaignPagenum: orderData?.campaignPagenum,
        promotionName: orderData?.promotionName,
        promotionId: orderData?.promotionId,
        value: orderData?.value,
        items: orderData?.items,
        paymentType: orderData?.paymentType,
        transactionId: order?.orderNumber,
      });
    }
  }, [order, orderData]);

  return (
    <LayoutWithTitle location={location} title="후원완료">
      <Section className="sec1">
        <Container>
          <BigTit>후원이 완료되었습니다.</BigTit>
          {!!gifts.length && (
            <GiftList
              defaultShowList
              items={gifts}
              title={
                <>
                  <span
                    css={`
                      color: #1cabe2;
                    `}
                  >
                    {order?.memberName}
                  </span>
                  {` `}
                  후원자님이 보낸 선물
                </>
              }
            />
          )}
          <p>
            후원해 주신 구호물품은 유니세프 코펜하겐 물류센터에 접수되었으며,
            후원품목과 구호물품이 일치하는 지역 중 긴급한 순서대로 전달됩니다.
          </p>
          <FormItem
            css={`
              margin-top: 100px;
            `}
          >
            <PayCompleteImage>
              <Tit size="s2">후원신청 내역</Tit>
            </PayCompleteImage>
            {order && (
              <PayDetail>
                <li className="ellipsis">
                  <span>후원 방법</span>
                  {order.supportTypeCode === SUPPORT_TERM_REGULAR && `정기`}
                  {order.supportTypeCode === SUPPORT_TERM_ONCE && `일시`}
                </li>
                <li>
                  <span>후원 분야</span>
                  {selectedCategory?.codeName}
                </li>
                <li>
                  <span>후원 금액</span>
                  {order.supportTypeCode === SUPPORT_TERM_REGULAR && `매월 `}
                  {supportAmount.toLocaleString()}원
                </li>
                <li>
                  <span>결제 정보</span>
                  {order.paymentTypeName}
                  {` `}
                  <br />
                  {order.payName} {order?.payNo}
                </li>
              </PayDetail>
            )}
          </FormItem>
        </Container>
      </Section>

      <Section className="sec2">
        <Container>
          <SecHeader>
            <div className="col-left">
              <BannerImage>
                <Image pcSrc={Banner1} mobileSrc={BannerM1} alt="" />
              </BannerImage>
            </div>
            <div className="col-right">
              <H2>
                더 많은 어린이가 꿈을 키워나갈 수 있도록 <br />
                생명을 구하는 선물을 함께 보내주세요
              </H2>
              <SnsButton
                text="유니세프 생명을 구하는 선물"
                url={`${process.env.SELF_URL}/involve/individual/ig/`}
              />
            </div>
          </SecHeader>

          <Bannerwrap>
            <BannerImage>
              <Link to="/about-us/clear/fund/unicef-kr">
                <Image
                  pcSrc={Banner2}
                  mobileSrc={BannerM2}
                  alt="후원금은 어떻게 전달이 되나요?"
                />
              </Link>
            </BannerImage>
            <BannerImage>
              <a
                href="http://www.unicef.or.kr/igreport/2019/?trackcode=ig20report_IGbtm"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  pcSrc={Banner3}
                  mobileSrc={BannerM3}
                  alt="2019년 생명을 구하는 선물 전달보고서"
                />
              </a>
            </BannerImage>
          </Bannerwrap>
          <ButtonFlex>
            <li>
              <Button outline onClick={() => navigate(`/`)}>
                홈으로 이동
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate(`/mypage/support-history`)}>
                나의 후원내역
              </Button>
            </li>
          </ButtonFlex>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
});

export default CompletePage;
