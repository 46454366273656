import AnchorText from './AnchorText';
import SnsFacebook from '@/assets/img/sns_facebook@2x.png';
import SnsFacebookM from '@/assets/img/sns_facebookM@2x.png';
import SnsKakao from '@/assets/img/sns_kakao@2x.png';
import SnsKakaoM from '@/assets/img/sns_kakaoM@2x.png';
import SnsTwitter from '@/assets/img/sns_twitter@2x.png';
import SnsTwitterM from '@/assets/img/sns_twitterM@2x.png';
import SnsUrl from '@/assets/img/sns_url@2x.png';
import SnsUrlM from '@/assets/img/sns_urlM@2x.png';
import Image from '@/components/Image';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';

declare global {
  interface Window {
    Kakao: {
      init: (key: string) => void;
      Link: {
        sendDefault: (params: any) => void;
      };
      Share: {
        sendDefault: (params: any) => void;
      };
    };
    FB: { init: any; login: any; getLoginStatus: any; api: any };
  }
}

const KAKAO_SDK_URL = `https://developers.kakao.com/sdk/js/kakao.min.js`;

const ButtonFlex = styled.ul`
  display: flex;
  justify-content: center;

  ${breakpoint(`tablet`)} {
    justify-content: space-around;
  }

  & > li {
    margin: 0 16px;
    width: 56px;

    ${breakpoint(`tablet`)} {
      width: 48px;
    }
  }
`;

interface SnsButtonProps {
  text?: string;
  url: string;
}

const SnsButton: FC<SnsButtonProps> = observer(({ text, url }) => {
  const popupStore = usePopupStore();

  const shareKakao = useCallback(() => {
    window.Kakao.Link.sendDefault({
      objectType: `text`,
      text: text || `유니세프`,
      link: {
        mobileWebUrl: url,
        webUrl: url,
      },
    });
  }, [text, url]);

  const initKakao = useCallback(() => {
    if (process.env.KAKAO_JAVASCRIPT_KEY) {
      window.Kakao.init(process.env.KAKAO_JAVASCRIPT_KEY);
    }
  }, []);

  const loadKakaoScript = useCallback(() => {
    if (document.querySelectorAll(`#kakao-sdk`).length === 0) {
      const script = document.createElement(`script`);
      script.id = `kakao-sdk`;
      script.src = KAKAO_SDK_URL;
      script.onload = initKakao;
      document.head.appendChild(script);
    }
  }, [initKakao]);

  useEffect(() => {
    loadKakaoScript();
  }, [loadKakaoScript]);

  const copyToClipboard = () => {
    const t = document.createElement(`textarea`);
    document.body.appendChild(t);
    t.value = url;
    t.select();
    document.execCommand(`copy`);
    document.body.removeChild(t);
    popupStore.show(`클립보드로 복사되었습니다`);
  };

  return (
    <ButtonFlex>
      <li>
        <AnchorText onClick={shareKakao}>
          <Image pcSrc={SnsKakao} mobileSrc={SnsKakaoM} alt="카카오" />
        </AnchorText>
      </li>
      <li>
        {/* localhost에서는 "Parameter 'href' should represent a valid URL" 에러 발생함. 고정아이피 또는 도메인에서만 정상작동함. */}
        <Link
          to={`http://www.facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank"
        >
          <Image pcSrc={SnsFacebook} mobileSrc={SnsFacebookM} alt="페이스북" />
        </Link>
      </li>
      <li>
        <Link
          to={`https://twitter.com/intent/tweet?text=${text || ``}&url=${url}`}
          target="_blank"
        >
          <Image pcSrc={SnsTwitter} mobileSrc={SnsTwitterM} alt="트위터" />
        </Link>
      </li>
      <li>
        <AnchorText onClick={copyToClipboard}>
          <Image pcSrc={SnsUrl} mobileSrc={SnsUrlM} alt="URL" />
        </AnchorText>
      </li>
    </ButtonFlex>
  );
});

export default SnsButton;
